import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { toJS } from 'mobx';
import * as moment from 'moment';
import { DataService } from 'src/app/core/services/data.service';
import { sum, swhichLabel, swhichRemarkLabel } from '../services/convert.service';
import { getRemarkNameFromParenthesises, getTimePolicy, groupBy, MappingService, pushToArray, pushToObject, toDateKey } from '../services/mapping.service';
import { MONTH_DATA, MONTH_OBJECT } from 'src/app/core/dummy/app';

@Pipe({
  name: 'sumReport'
})
export class SumReportPipe implements PipeTransform {

  transform(value: any, data?: Array<any>, key?: any, sumField?: any): any {
    if (!data || data.length == 0) return 0;
    const filter = data.filter(m => m.invoice_type.key === key);
    const total = MappingService.sum(filter.slice(), sumField);
    return total;
  }

}

@Pipe({
  name: 'attendenceSubject'
})
export class attendenceSubjectPipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(batchKey: string, scheduleKey: string, field: string) {
    const schedules = pushToObject(await this.afs.collection('institute_training_level_batch').doc(batchKey).collection('schedules').doc(scheduleKey).get().toPromise());
    return schedules.schedule_subject.subject.name;
  }

}

@Pipe({
  name: 'sumAttendence'
})
export class SumAttendencePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(schoolKey: string, batchKey: string, date: any) {
    const dateKey = toDateKey(date);
    const att = pushToObject(await this.afs.collection('stores').doc(schoolKey).collection('sys_option').doc('general').get().toPromise());
    const data = pushToArray(await this.afs.collection('stores').doc(schoolKey).collection('student_attendance_movement', ref => ref.where('batchKey', '==', batchKey).where('checkIn_date_key', '==', dateKey).where('remark.key', '==', att['attendanceA'].key)).get().toPromise());
    const gdata = groupBy(data, 'studentKey', 'studentKey')
    return 'Absent:' + ' ' + gdata?.length + ' ' + 'students';

  }

}


@Pipe({
  name: 'studentAttendence'
})
export class StudentAttendencePipe implements PipeTransform {
  constructor(
  ) { }
  async transform(timeAttendance: any[], shift: string) {
    const morningTime = 5;
    const afternoonTime = 12.01;
    const currentMHour = getTimePolicy(morningTime);
    const currentAfHour = getTimePolicy(afternoonTime);
    // const currentAfHour =  { fromHour: 12, toHour: 24 };

    let gdata = null
    if (!timeAttendance) return null
    if (shift === "morning") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour === currentMHour.fromHour && m.policy_hour.toHour === currentMHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    if (shift === "afternoon") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour >= currentAfHour.fromHour && m.policy_hour.toHour <= currentAfHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    return swhichLabel(gdata ? gdata : null)

  }

}

@Pipe({
  name: 'studentAttendenceRemark'
})
export class StudentAttendenceRemarkPipe implements PipeTransform {
  constructor(
  ) { }
  async transform(timeAttendance: any[], shift: string) {
    const morningTime = 5;
    const afternoonTime = 12.01;
    const currentMHour = getTimePolicy(morningTime);
    const currentAfHour = getTimePolicy(afternoonTime);
    // const currentAfHour =  { fromHour: 12, toHour: 24 };

    let gdata = null
    if (!timeAttendance) return null
    if (shift === "morning") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour === currentMHour.fromHour && m.policy_hour.toHour === currentMHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    if (shift === "afternoon") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour >= currentAfHour.fromHour && m.policy_hour.toHour <= currentAfHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    return gdata ? gdata : null

  }

}

@Pipe({
  name: 'scanTime'
})
export class ScanTimePipe implements PipeTransform {
  constructor(
  ) { }
  async transform(timeAttendance: any[], type: number, shift: string) {
    const morningTime = 5;
    const afternoonTime = 12.01;
    const currentMHour = getTimePolicy(morningTime);
    const currentAfHour = getTimePolicy(afternoonTime);
    let gdata = null
    if (!timeAttendance) return null
    if (type === 1 && shift === "morning") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour === currentMHour.fromHour && m.policy_hour.toHour === currentMHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    if (type === 1 && shift === "afternoon") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour >= currentAfHour.fromHour && m.policy_hour.toHour <= currentAfHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }

    if (type === 2 && shift === "morning") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 2 && m.policy_hour.fromHour === currentMHour.fromHour && m.policy_hour.toHour === currentMHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    if (type === 2 && shift === "afternoon") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 2 && m.policy_hour.fromHour >= currentAfHour.fromHour && m.policy_hour.toHour <= currentAfHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    return gdata ? gdata?.scan_date.toDate() : null
  }

}
@Pipe({
  name: 'attNote'
})
export class attNotePipe implements PipeTransform {
  constructor(
    private ds: DataService
  ) { }
  async transform(batchMember, session: any, isConfirmed: any, date: any, schoolConfig: any) {
    // const fromDate = moment(date).startOf("day").toDate();
    // const toDate = moment(date).endOf("day").toDate();
    const dateKey = toDateKey(date);
    // const timeAttendanceMovement = pushToArray(await this.ds.firestore().collection('students').doc(m.student.key).collection("student_attendance_movement").where('admissionKey', '==', m.admissionKey).where("create_date_key", "==", dateKey).get())
    //       const timeAttendance = pushToArray(await this.ds.firestore().collection('students').doc(m.student.key).collection("time_attendance").where('admissionKey', '==', m.admissionKey).where("create_date_key", "==", dateKey).get())
    const { admissionKey, student } = batchMember;
    const studentKey = student.key
    if (isConfirmed) {
      const prioritizedMovements = pushToArray(await this.ds.firestore().collection('students').doc(studentKey).collection("student_attendance_movement")
        .where('isPrioritizedMovement', '==', true).where('admissionKey', '==', admissionKey).where("create_date_key", "==", dateKey).get());
      const data = pushToArray(await this.ds.firestore().collection('students').doc(studentKey).collection("student_attendance_movement").where('admissionKey', '==', admissionKey).where("create_date_key", "==", dateKey).get());

      const data_withFinalRemark = data.map((movement) => {
        let finalRemark = movement.remark;

        const prioritizedMovement = prioritizedMovements.find((item) => {
          return (movement?.session?.fromHoursNumber >= item?.prioritizedShift?.fromHour_
            && movement?.session?.fromHoursNumber < item?.prioritizedShift?.toHour_) ||
            (movement?.session?.fromHoursNumber >= item?.shiftPolicyHour?.fromHour_
              && movement?.session?.fromHoursNumber < item?.shiftPolicyHour?.toHour_)
        });

        if (!prioritizedMovement) finalRemark.name = movement.remark.name;
        else finalRemark.name = `${movement.remark.name} (${prioritizedMovement.remark.name})`;
        return { ...movement, remark: finalRemark };
      });

      const attData = data_withFinalRemark.filter(f => f.sessionKey === session.key)[0];

      return attData ? attData : null;
    } else {

      const prioritizedMovements = pushToArray(await this.ds.firestore().collection('students').doc(studentKey).collection("student_attendance_movement")
        .where('isPrioritizedMovement', '==', true).where('admissionKey', '==', admissionKey).where("create_date_key", "==", dateKey).get());
      const data = pushToArray(await this.ds.firestore().collection('students').doc(studentKey).collection("time_attendance").where('admissionKey', '==', admissionKey).where("create_date_key", "==", dateKey).get());

      const data_withFinalRemark = data.map((movement) => {
        let finalRemark = movement.remark;

        if (prioritizedMovements && prioritizedMovements.length > 0) {
          const prioritizedMovement = prioritizedMovements.find((item) => {
            return (session?.fromHoursNumber >= item?.prioritizedShift?.fromHour_
              && session?.fromHoursNumber < item?.prioritizedShift?.toHour_) ||
              (session?.fromHoursNumber >= item?.shiftPolicyHour?.fromHour_
                && session?.fromHoursNumber < item?.shiftPolicyHour?.toHour_)
          });

          if (!prioritizedMovement) finalRemark.name = movement.remark.name;
          finalRemark.name = prioritizedMovement ? `${movement.remark.name} (${prioritizedMovement.remark.name})` : movement.remark.name;
          return { ...movement, remark: finalRemark };
        } else if (movement.isHasPermission) {
          if (movement.halfDayPermission) {
            if (session.fromHoursNumber >= movement.halfDayPermission.shift.fromHour_ && session.fromHoursNumber < movement.permissionShift.shift.toHour_) {
              finalRemark.name = movement.remark.name;
              return { ...movement, remark: finalRemark };
            } else {
              return null;
            }
          } else {
            return { ...movement, remark: finalRemark };
          }
        } else {
          return null;
        }
      });
      const attData = data_withFinalRemark[0];
      return attData ? attData : null;
    }

  }
}

@Pipe({
  name: 'canConfirmAtt'
})
export class canConfirmAttPipe implements PipeTransform {
  constructor() { }
  async transform(session: any, att: any) {
    // console.log('session', session.key, session.name, att)
    // console.log('session', session.key, session.name)
    // console.log('confirm log', att.map(m => {
    //   return{
    //     key:m.session.key,
    //     name:m.session.name
    //   }
    // }))

    if (att && att.length > 0) {
      const s = att.find(f => f.sessionKey === session.key);
      return s ? true : false
    } else {
      return false
    }
  }
}

@Pipe({
  name: 'studentAttendanceD'
})
export class studentAttendanceDPipe implements PipeTransform {

  transform(s: any, attendanceLists: any) {
    let gdata = null;
    if (s && attendanceLists) {
      gdata = attendanceLists.find(m => m.create_date_key === s.dateKey && m.sessionKey === s.key);
      return gdata ? gdata.remark.name : null;
      // return swhichLabel(gdata ? gdata : null)
    } else {
      return null
    }
  }
}



@Pipe({
  name: 'studentAttendanceA'
})
export class studentAttendanceAPipe implements PipeTransform {
  transform(s: any, attendanceLists: any) {
    if (attendanceLists) {
      const p = attendanceLists.filter(f => {
        return f.create_date_key === s.dateKey && getRemarkNameFromParenthesises(f.remark.name) === 'A'
      });
      return p && p.length > 0 ? 1 : 0
    } else {
      return 0
    }
  }
}


@Pipe({
  name: 'studentAttendanceP'
})
export class studentAttendancePPipe implements PipeTransform {
  transform(s: any, attendanceLists: any) {
    if (attendanceLists) {
      const p = attendanceLists.filter(f => {
        return f.create_date_key === s.dateKey && getRemarkNameFromParenthesises(f.remark.name) === 'P';
      });
      return p && p.length > 0 ? 1 : 0
    } else {
      return 0
    }
  }
}


@Pipe({
  name: 'studentAttendanceTotalA'
})
export class studentAttendanceTotalAPipe implements PipeTransform {
  transform(attendanceLists: any) {
    if (attendanceLists) {

      const aData = attendanceLists.filter(f => f.remark.name === 'A')
      const data = groupBy(aData, 'create_date_key', 'create_date_key');
      // console.log('data', data);
      // const p = data.filter(f => f.remark.name === 'A');
      return data && data.length > 0 ? data.length : 0
    } else {
      return 0
    }
  }
}


@Pipe({
  name: 'studentAttendanceTotalP'
})
export class studentAttendanceTotalPPipe implements PipeTransform {
  transform(attendanceLists: any) {
    if (attendanceLists) {
      const data = groupBy(attendanceLists, 'create_date_key', 'create_date_key')
      const p = data.filter(f => f.remark.name === 'OK');
      return p && p.length > 0 ? p.length : 0
    } else {
      return 0
    }
  }
}


@Pipe({
  name: 'studentAttendanceMonthTotalP'
})
export class studentAttendanceMonthTotalPPipe implements PipeTransform {
  transform(s: any, attendanceLists: any) {
    if (attendanceLists) {
      const p = attendanceLists.filter(f => f.create_date_month_key === Number(s.monthKey) && f.remark.name === 'P');
      const data = groupBy(p, 'create_date_key', 'create_date_key')
      return data && data.length > 0 ? data.length : 0
    } else {
      return 0
    }
  }
}

@Pipe({
  name: 'studentAttendanceMonthTotalA'
})
export class studentAttendanceMonthTotalAPipe implements PipeTransform {
  transform(s: any, attendanceLists: any) {
    if (attendanceLists) {
      const p = attendanceLists.filter(f => f.create_date_month_key === Number(s.monthKey) && f.remark.name === 'A');
      const data = groupBy(p, 'create_date_key', 'create_date_key')
      return data && data.length > 0 ? data.length : 0
    } else {
      return 0
    }
  }
}

@Pipe({
  name: 'studentAttendanceStatisticTotalA'
})
export class studentAttendanceStatisticTotalAPipe implements PipeTransform {
  transform(dateMonthKey: any, attendanceStats: any) {
    if (attendanceStats) {
      const result = attendanceStats.find(f => dateMonthKey == f.key);
      return result ? result.totalA : 0
    } else {
      return 0
    }
  }
}

@Pipe({
  name: 'studentAttendanceStatisticTotalP'
})
export class studentAttendanceStatisticTotalPPipe implements PipeTransform {
  transform(dateMonthKey: any, attendanceStats: any) {
    if (attendanceStats) {
      const result = attendanceStats.find(f => dateMonthKey == f.key);
      return result ? result.totalAP : 0
    } else {
      return 0
    }
  }
}

@Pipe({
  name: 'studentAttendanceYearTotalA'
})
export class studentAttendanceYearTotalAPipe implements PipeTransform {
  transform(attendanceLists: any) {
    if (attendanceLists) {
      const p = attendanceLists.filter(f => f.remark.name === 'A');
      const data = groupBy(p, 'create_date_key', 'create_date_key')
      return data && data.length > 0 ? data.length : 0
    } else {
      return 0
    }
  }
}

@Pipe({
  name: 'studentAttendanceStatisticYearlyTotalA'
})
export class studentAttendanceStatisticYearlyTotalAPipe implements PipeTransform {
  transform(attendanceLists: any) {
    if (attendanceLists) {
      return attendanceLists.reduce((n, { totalA }) => n + totalA, 0);
    } else {
      return 0
    }
  }
}


@Pipe({
  name: 'studentAttendanceStatisticYearlyTotalAP'
})
export class studentAttendanceStatisticYearlyTotalAPPipe implements PipeTransform {
  transform(attendanceLists: any) {
    if (attendanceLists) {
      return attendanceLists.reduce((n, { totalAP }) => n + totalAP, 0);
    } else {
      return 0
    }
  }
}

@Pipe({
  name: 'studentAttendanceStatisticYearlyTotal'
})
export class studentAttendanceStatisticYearlyTotalPipe implements PipeTransform {
  transform(attendanceLists: any) {
    if (attendanceLists) {
      return attendanceLists.reduce((n, { totalA, totalAP }) => n + totalA + totalAP, 0);
    } else {
      return 0
    }
  }
}
@Pipe({
  name: 'numToKhNum'
})
export class numToKhNumPipe implements PipeTransform {
  transform(num: number): any {
    if (!num) return null;
    const khNum = "០,១,២,៣,៤,៥,៦,៧,៨,៩".split(",")
    const enNum = "0,1,2,3,4,5,6,7,8,9".split(",")
    const khmer = num.toString().split("").map((a) => khNum[enNum.indexOf(a)] ?? a)
    return khmer.join("")
  }
}

@Pipe({
  name: 'studentDayAttendance'
})
export class studentDayAttendancePipe implements PipeTransform {
  constructor(
  ) { }
  transform(dateKey: any, attendanceLists: any) {
    let gdata = null
    gdata = attendanceLists.filter(m => m.create_date_key === dateKey);
    return gdata || []
  }
}

@Pipe({
  name: 'studentDayAttendanceCol'
})
export class studentDayAttendanceColPipe implements PipeTransform {
  constructor(
  ) { }
  transform(date: any, attendanceLists: any) {
    const dateKey = toDateKey(date);
    let gdata = null
    gdata = attendanceLists.filter(m => m.create_date_key === dateKey);
    return gdata ? gdata.length : 0
  }
}


@Pipe({
  name: 'attendanceNote'
})
export class attendanceNotePipe implements PipeTransform {
  constructor(
  ) { }
  async transform(timeAttendance: any[], shift: string) {
    const morningTime = 5;
    const afternoonTime = 12.01;
    const currentMHour = getTimePolicy(morningTime);
    const currentAfHour = getTimePolicy(afternoonTime);

    let gdata = null
    if (!timeAttendance) return null
    if (shift === "morning") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour === currentMHour.fromHour && m.policy_hour.toHour === currentMHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    if (shift === "afternoon") {
      gdata = timeAttendance.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour >= currentAfHour.fromHour && m.policy_hour.toHour >= currentAfHour.toHour
        || m.remark.name === "P" || m.remark.name === "M")[0];
    }
    return gdata ? gdata.note : null
  }

}


@Pipe({
  name: 'remarkAttendence'
})
export class RemarkAttendencePipe implements PipeTransform {
  constructor(
  ) { }
  async transform(remark: any) {
    return swhichRemarkLabel(remark)
  }

}




@Pipe({
  name: 'monthNamePipe'
})
export class MonthNamePipe implements PipeTransform {

  transform(value: any): unknown {
    if (value) {
      const dateKeyStr = value.toString();
      const monthKey = parseInt(dateKeyStr.substring(4, 6), 10); // Extract MM part
      const month = MONTH_DATA.find(m => m.key === monthKey);
      return month ? month.kh : null;
    }
    return null;
  }
}




@Pipe({
  name: 'filterAttMonthPipe'
})
export class FilterAttMonthPipe implements PipeTransform {

  transform(value: any[], month): unknown {
    if (value) {

      return month ? month.kh : null;
    }
    return null;
  }
}



@Pipe({
  name: 'instructorMonthlyAttendancePipe'
})
export class InstructorMonthlyAttendancePipePipe implements PipeTransform {

  transform(value: any[], month): unknown {
    if (value) {
      const att = value.filter(f => f.yearMonth === month)
      if (att) {
        const absent = att.filter(f => f.absent).length || 0;
        const permission = att.filter(f => f.permission).length || 0;
        const data = {
          absent: absent || 0,
          permission: permission || 0,
        }
        return data;
      }
    }
    return null;
  }
}


@Pipe({
  name: 'roundDown'
})
export class roundDownPipe implements PipeTransform {
  constructor(
  ) { }
  transform(number: number) {
    const num = Math.floor(number)
    return num
  }

}



